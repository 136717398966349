// PricingTable.tsx

import React from 'react';
import './PricingTable.css';

export interface ServiceItem {
  serviceName: string;
  nonMember: string;
  member: string;
}

export interface PricingTableProps {
  items: ServiceItem[];
}

const PricingTable: React.FC<PricingTableProps> = ({ items }) => {
  return (
    <table className="pricing-table">
      <thead>
        <tr>
          <th>SERVICE</th>
          <th>NON-MEMBER PRICE</th>
          <th>MEMBER PRICE</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={index}>
            <td>{item.serviceName}</td>
            <td>{item.nonMember}</td>
            <td>{item.member}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PricingTable;
