import React from 'react';
import './MapLinkComponent.css'; // Make sure the path to your CSS file is correct
import tempo_logo from "../assets/tempo_logo.svg";

const MapLinkComponent: React.FC = () => {
  const address = "1729 Brittmoore RD A, Houston, TX 77043";
  const googleMapsLink = "https://maps.app.goo.gl/RiJdjDhtcXMx6fZRA";

  return (
    <div className="map-container">
      <a href={googleMapsLink} target="_blank" rel="noopener noreferrer" className="map-link">
        <img src={tempo_logo} alt="Logo" className="map-logo" />
        <div className="map-address">{address}</div>
      </a>
    </div>
  );
};

export default MapLinkComponent;
