import React from "react";
import { ReactComponent as TempoLogo } from "../assets/tempo_and_logo.svg";
import { ReactComponent as BurgerButton } from "../assets/burger_button.svg"
import { Link, useLocation } from "react-router-dom";
import "./PricingPage.css";
import InfoFooter from "../components/InfoFooter";
import Footer from "../components/FooterComponent";
import PricingCardComponent from "../components/PricingCardComponent";
import PricingTable from "../components/PricingTable";


const PricingPage = () => {
  return (
    <div className="pricing-container">
      <div className="pricing-title-container">
        <h1 className="pricing-title">
          PRICING
        </h1>
        <p className="pricing-subtitle">
          Unlock a world of premium wellness and exhilarating sports with our flexible membership plans tailored to fit your lifestyle.
        </p>
      </div>
      <div className="pricing-section-2">
        <h1>
          <span>MEMBERSHIP</span>
          <br></br>
          PLANS
        </h1>
        {/* <div className="membership">
          <PricingCardComponent price="$50" benefits={["Benefit One", "Benefit Two", "Benefit Three"]} />
        </div> */}
        <div className="membership">
          <PricingCardComponent
            membershipName="F̶o̶u̶n̶d̶e̶r̶'̶s̶ ̶M̶e̶m̶b̶e̶r̶s̶h̶i̶p̶ (SOLD OUT)"
            description="A limited-time, exclusive opportunity for our first 50 members to unlock the entire world of Tempo's Padel & Pickleball Club."
            darkMode={true}
            price="$99"
            ctaUrl="https://tempo.playbypoint.com/memberships/2549"
            benefits={
              ["Unlimited Pickleball",
                "8 Padel Games per month",
                "Four Heat+Cold Recovery Sessions",
                "Pro Shop Discounts",
                "Booking Priority (28 days in advance)"]} />
        </div>
      </div>
      <div className="pricing-table-container">
        <h1>
          <span>PRICE </span>LIST
        </h1>
        <PricingTable items={[
          { serviceName: "Padel Court 1.5hr (per player)", nonMember: "$20", member: "$15" },
          { serviceName: "Pickleball Court 1h (per player)", nonMember: "$10", member: "$5" },
          { serviceName: "Book in advance", nonMember: "14 days", member: "21 days" },
          { serviceName: "Free cancellation", nonMember: "24 hours", member: "12 hours" },
          { serviceName: "Pro-Shop discounts", nonMember: "No", member: "Yes" },
          { serviceName: "Open play pickleball", nonMember: "$15", member: "$10" },
          { serviceName: "Event discounts", nonMember: "No", member: "Yes" }
        ]} />
      </div>
      <div className="booknow">
        <div className="booknow-title">
          <span className="style2">JOIN </span>
          NOW
        </div>
        <p className="booknow-description">
          Unlock the full TEMPO experience with our app! Create an account to reserve courts, sign up for memberships, shop the latest gear, join leagues, book clinics, and enter tournaments!
        </p>
        <button className="pricing-booknow-button" onClick={() => { window.location.href = "https://tempo.playbypoint.com/users/sign_up" }}>SIGN UP</button>
      </div>
      <InfoFooter />
      <Footer />
    </div>
  );
};

export default PricingPage;
