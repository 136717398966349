import React, { useState } from 'react';
import "./ContactUsForm.css";
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import ThankYouComponent from './ThankYouComponent';

// Define the state types for the form fields
type FormState = {
  name: string;
  email: string;
  phoneNumber: string;
  description: string;
};

type ContactUsFormProps = {
};

const ContactUsForm: React.FC<ContactUsFormProps> = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [description, setDescription] = useState("");
  const [messageSent, setMessageSent] = useState(false);

  const usersCollectionRef = collection(db, "contact_us");

  const submitContactUsForm = async (event: any) => {
    event.preventDefault();
    await addDoc(usersCollectionRef, {
      name: name,
      email: email,
      phoneNumber: phoneNumber,
      description: description,
      datetime: serverTimestamp(),
    });

    setName("");
    setEmail("");
    setPhoneNumber("");
    setDescription("");

    setMessageSent(true);
  };

  return (
    <div className="contact-form-container" style={{ fontFamily: 'var(--font-family)' }}>
      <h2>CONTACT US</h2>
      <p>
        Our one-stop-shop for inquiries. Do you have questions about us? Just
        state your business and we will play ball.
      </p>
      <>
        {
          messageSent ? (
            <ThankYouComponent thankYouMessage="Thank you for your message. We will contact you shortly!" />
          ) :
            (
              <form onSubmit={submitContactUsForm}>
                <div className="input-wrapper">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name *"
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    required
                  />
                </div>
                <div className="input-wrapper">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email *"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    required
                  />
                </div>
                <div className="input-wrapper">
                  <input
                    type="tel"
                    name="phoneNumber"
                    placeholder="Phone Number *"
                    value={phoneNumber}
                    onChange={(event) => {
                      setPhoneNumber(event.target.value);
                    }}
                    required
                  />
                </div>
                <div className="textbox-wrapper">
                  <textarea
                    name="description"
                    value={description}
                    onChange={(event) => {
                      setDescription(event.target.value);
                    }}
                    placeholder="Description *"
                    title="Title text"
                    required
                  />
                </div>
                <button type="submit">Send</button>
              </form>
            )
        } </>
    </div>
  );
};

export default ContactUsForm;
