import React from "react";
import { ReactComponent as TempoLogo } from "../assets/tempo_and_logo.svg";
import { ReactComponent as BurgerButton } from "../assets/burger_button.svg"
import { Link, useLocation } from "react-router-dom";
import "./AboutUsPage.css";
import InfoFooter from "../components/InfoFooter";
import Footer from "../components/FooterComponent";

const AboutUsPage = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-title-container">
        <h1 className="about-us-title">
          ABOUT US
        </h1>
        <p className="about-us-subtitle">
          More than a destination — a journey where play, wellness, and connection unite to create an active social lifestyle.
        </p>
      </div>
      <div className="about-us-section-2">
        <h1>
          THE TEMPO EXPERIENCE
        </h1>
        <p>
          A lifestyle where every match is more than a game - it's a step towards enhancing your physical and mental well-being, surrounded by a supportive community of fellow enthusiasts.
          <br>
          </br>
          <br></br>
          We're commited to creating an inclusive environment where everyone can experience the joy of racquet sports, foster lasting relationships, and embark on a journey of wellness together.
        </p>
      </div>
      <div className="our-facilities-container">
        <span className="our-facilities-title">
          <span className="style2">OUR </span >
          FACILITIES
        </span>
        <span className="amenity-description">
          Experience state-of-the-art padel courts and pickleball courts, a rejuvenating wellness area, and a dynamic bar lounge. Tailored for play, wellness, and connection, our facilities enable every member to embrace a unique lifestyle at their own tempo.
        </span>
        <div className="amenity1">
        </div>
        <span className="amenity-description">
          <span className="style2">PLAY </span>
          | Four pickleball courts, and four superpanoramic padel courts with 360 views. Enjoy open play, court reservations, leagues, tournaments, clinics, and private lessons.
          <br></br>
          The pro shop caters to pickleball and padel players, featuring top-tier equipment and professional guidance for every skill level.
        </span>
        <div className="amenity2">
        </div>
        <span className="amenity-description">
          <span className="style2" >WELLNESS </span>
          | Elevate your wellbeing and recovery with hot or cold therapy with our next-generation sauna and ice bath amenities that can go from 37°F to 230°F.
        </span>
        <div className="amenity3">
        </div>
        <span className="amenity-description">
          <span className="style2">CONNECTION </span>
          | Whether you are getting ready for your game, or unwinding after a match with nutritious snacks and drinks, our lounge and bar area is designed to promote a lively atmosphere among our community and offer the best products for your journey.
        </span>
      </div>
      <div className="booknow">
        <div className="booknow-title">
          <span className="style2">BOOK </span>
          NOW
        </div>
        <p className="booknow-description">
          Unlock the full TEMPO experience with our app! Create an account to reserve courts, sign up for memberships, shop the latest gear, join leagues, book clinics, and enter tournaments!
        </p>
        <button className="booknow-button" onClick={() => { window.location.href = "https://tempo.playbypoint.com/memberships/2549" }}>BOOK</button>
      </div>
      <InfoFooter />
      <Footer />
    </div>
  );
};

export default AboutUsPage;
