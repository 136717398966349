import React from "react";
import "./ContactUsPage.css";

// player background img
import tempoPlayerCourtSmall from "../assets/tempo_player_court_small.png";
import tempoPlayerCourtMedium from "../assets/tempo_player_court_medium.png";
import tempoPlayerCourtLarge from "../assets/tempo_player_court_large.png";

import emailIcon from "../assets/email_icon.svg";
import phoneIcon from "../assets/phone_icon.svg";
import igIconBlack from "../assets/ig_icon_black.svg";
import MapLinkComponent from "../components/MapLinkComponent";
import Footer from "../components/FooterComponent";
import InfoFooter from "../components/InfoFooter";
import ContactUsForm from "../components/ContactUsForm";


const ContactUsPage = () => {
  return (
    <div className="contact-container">
      <div className="contact-title-container">
        <h1 className="contact-title">
          CONTACT US
        </h1>
        <img
          className="contact-player-image"
          srcSet={`${tempoPlayerCourtSmall} 500w, ${tempoPlayerCourtMedium} 1000w, ${tempoPlayerCourtLarge} 2000w`}
          sizes="(max-width: 600px) 480px,
        (max-width: 900px) 800px,
        1200px"
          src="${tempoPlayerCourtSmall}" // Fallback for browsers that do not support srcset
          alt="Image of a person's hands and feet holding a padel racquet in a padel court with two padel balls in houston, texas."
        />
      </div>
      <div className="contact-items-container">
        <div className="contact-item">
          <div className="contact-item-title">
            <img
              className="contact-icon"
              src={emailIcon}
              alt="Email Icon"
            />
            <div>
              E-MAIL
            </div>
          </div>
          <div className="contact-subtext">
            info@tempo.club
          </div>
        </div>
        <div className="contact-item">
          <div className="contact-item-title">
            <img
              className="contact-icon"
              src={phoneIcon}
              alt="Phone Icon"
            />
            <div>
              PHONE NUMBER
            </div>
          </div>
          <div className="contact-subtext">
            +1 713 570 6716
          </div>
        </div>
        <div className="contact-item">
          <div className="contact-item-title">
            <img
              className="contact-icon"
              src={igIconBlack}
              alt="Instagram Icon"
            />
            <div>
              INSTAGRAM
            </div>
          </div>
          <div className="contact-subtext">
            @tempopadelclub
          </div>
        </div>
      </div>
      <div className="contact-us-form-container">
        <ContactUsForm />
      </div>
      <div className="map-link-container">
        <MapLinkComponent />
      </div>
      <InfoFooter />
      <Footer />
    </div>
  );
};

export default ContactUsPage;
