// ErrorPage.tsx
import React from 'react';

const ErrorPage: React.FC = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>404</h1>
      <h2 style={styles.text}>Page Not Found.</h2>
      <p style={styles.text2}>Please contact us at</p>
      <p style={styles.text2}>info@tempo.club</p>
    </div>
  );
};

export default ErrorPage;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'start',
    alignItems: 'center',
    height: '100%',
    marginTop: '10vh',
  },
  header: {
    fontSize: '4rem',
    fontWeight: 'bold',
  },
  text: {
    fontSize: '1.5rem',
    margin: '1em',
  },
  text2: {
    fontSize: '1rem',
  },
};
