import React from 'react';
import './InfoFooter.css';
import { ReactComponent as TempoLogo } from "../assets/tempo_and_logo.svg";
import { ReactComponent as IGWhite } from "../assets/ig_icon_white.svg";
import { Link, useLocation } from "react-router-dom";


const InfoFooter: React.FC = () => {
  const location = useLocation();

  const scrollToTopIfNeeded = () => {
    if (location.pathname === '/') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // for smooth scrolling
      });
    }
  };

  return (
    <div className="infofooter">
      <div className="infofooterlogo">
        <TempoLogo />
      </div>
      <div className="infofooterinner">
        <div className="social-media">
          <a
            href="https://www.instagram.com/tempopadelclub"
            target="_blank"
            rel="noopener noreferrer">
            <IGWhite className="instagram-icon" />
          </a>
        </div>
        <div className="menu">
          <Link to="/" onClick={scrollToTopIfNeeded}>
            <div className="menu-item">HOME</div>
          </Link>
          <Link to="/pricing" onClick={scrollToTopIfNeeded}>
            <div className="menu-item">PRICING</div>
          </Link>
          <Link to="/about-us">
            <div className="menu-item">ABOUT US</div>
          </Link>
          <Link to="/contact">
            <div className="menu-item">CONTACT</div>
          </Link>
        </div>
        <div className="address">
          <div className="visit-us">VISIT US:</div>
          <a href="https://maps.app.goo.gl/vnJKBD2suLMAiyLc7" target="_blank">
            <div>1729 Brittmoore RD A, TX 77043</div>
          </a>
        </div>
        <div className="contact">
          <div className="contact-us">CONTACT US:</div>
          <a href="mailto:info@tempo.club?subject=Inquiry%3A%20Tempo%20Padel%20%26%20Pickleball%20Club%20Houston&body=Hello%20Tempo%20Team%2C%0A%0A%3CI%20would%20like%20to%20know%20more%20about...%3E%0A%0AThanks%2C%0A%3CInsert%20Name%20Here%3E">info@tempo.club</a>
        </div>
        {/* <div className="actions">
          <div className="btn-member">BECOME A MEMBER</div>
          <button className="btn-signin">SIGN IN</button>
        </div> */}
      </div >
    </div >
  );
}

export default InfoFooter;